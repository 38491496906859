@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.cartPage {
  height: 0;
  flex: 1;
  display: flex;
  .pageContent {
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    .topBar {
      flex: 0 0 100px;
      max-height: 90px;
      border-bottom: 1px solid $border-color;
      padding: 0 90px;
      display: flex;
      align-items: center;
      .navLogo {
        height: 50%;
        width: auto;
      }
    }
    .header {
      padding: 50px 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .texts {
        .titleHead {
          font-size: 35px;
          font-weight: bold;
        }
        .subTitleHead {
          font-size: 20px;
          font-weight: 500;
        }
      }
      .btnAsset {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $border-color;
        padding: 10px 15px;
        border-radius: 10px;
        img {
          height: 25px;
          width: auto;
        }
        span {
          font-size: 22px;
          font-weight: bold;
          margin-left: 10px;
        }
      }
    }
    .cartList {
      height: 0;
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      .cartItmCard {
        flex: 0 0 160px;
        height: 160px;
        max-height: 160px;
        border-radius: 20px;
        border: 1px solid $border-color;
        margin: 20px 80px;
        display: flex;
        align-items: center;
        padding: 0 60px;
        position: relative;
        .coinDetail {
          display: flex;
          align-items: center;
          img {
            height: 80px;
            width: 80px;
            object-fit: contain;
            margin-right: 20px;
          }
          .cardText {
            .name {
              font-size: 26px;
              font-weight: 600;
            }
            .symbool {
              font-size: 20px;
              font-weight: 600;
              color: $text-fade-color;
            }
          }
        }
        .count {
          display: flex;
          margin: auto;
          .btnMinus,
          .btnPlus {
            height: 50px;
            width: 50px;
            border-radius: 10px;
            border: 1px solid $border-color;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            font-weight: bold;
            line-height: 1;
            @include btn-animate($primary-color, 100%, 250%, 260%, -260%);
          }
          .input {
            width: 80px;
            text-align: center;
            background: none;
            border: none;
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
        .amount {
          font-size: 22px;
          font-weight: bold;
        }
        .closeCircle {
          height: 30px;
          width: 30px;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $border-color;
          top: -13px;
          right: -13px;
          background: white;
          @include btn-animate($primary-color, 100%, 250%, 260%, -260%);
          position: absolute;
          img {
            height: 12px;
          }
        }
      }
    }
  }
  .sideBar {
    flex: 0 0 25%;
    border-left: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo {
      width: 60%;
      margin-bottom: 70px;
    }
    .btnLogin,
    .btnRegister {
      width: 60%;
      height: 70px;
      border-radius: 15px;
      margin: 10px 0;
      background-color: $app-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      color: white;
      @include btn-animate(white);
    }
    .btnLogin {
      background: $primary-color;
    }
    .bare {
      width: 60%;
      .btnLogin {
        margin-top: 30px;
        width: 100%;
      }
    }
  }
}
