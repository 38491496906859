@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.landingPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  .pageWrap {
    height: 0;
    flex: 1;
    display: flex;
    justify-content: space-between;
    .textSide {
      flex: 0 0 50%;
      padding-left: 90px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .subtitle {
        font-size: 45px;
        font-weight: bold;
        color: $primary-color;
      }
      .title {
        font-size: 75px;
        margin: 2% 0;
        font-weight: bold;
        color: $app-color;
      }
      .searchWrap {
        margin-top: 40px;
        height: 90px;
        width: 85%;
        border-radius: 20px;
        border: 1px solid $border-color;
        display: flex;
        overflow: hidden;
        .input {
          flex: 1;
          border: none;
          background: none;
          padding: 0 40px;
          font-size: 24px;
        }
        .btnGo {
          width: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-left: 1px solid $border-color;
          @include btn-animate($primary-color, 100%, 250%, 260%, -260%);
          .icon {
            height: 50%;
            width: auto;
          }
        }
      }
    }
    .bgHome {
      margin-top: 20px;
      margin-right: 90px;
    }
  }
}
