@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.navbar {
  flex: 0 0 90px;
  border-bottom: 1px solid $border-color;
  display: flex;
  .logo {
    height: 45px;
    width: auto;
    margin: auto;
  }
  .btnLeft,
  .btnRight {
    flex: 0 0 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include btn-animate($primary-color, 100%, 250%, 260%, -260%);
    img {
      height: 40%;
    }
  }
  .btnLeft {
    border-right: 1px solid $border-color;
  }
  .btnRight {
    border-left: 1px solid $border-color;
  }
}
